<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  watch: {
    $route(to) {

      let corpToken = to.query['corpToken'] || '';
      if (corpToken) {
        localStorage.setItem('corpToken', corpToken)
      }
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
